import { Button, Form, Select } from 'antd';
import { getStoreAddress, IManualOrderFormName } from '../../utils';
import { ManualOrderAddressForm } from '../ShippingAddress';
import styles from '../../index.module.scss';
import { useContext, useEffect, useState } from 'react';
import { HighlightedText } from '../../../../../../shared/components/HighlightedText';
import { AuthContext } from '../../../../../../shared/contexts/Auth';
import { envs } from 'shared/utils/env';
const { appType } = envs;

interface IStoreAddress {
	isNewStoreAddress: boolean;
	setIsNewStoreAddress: any;
	addNewStoreAddress: any;
	editStoreAddress: any;
	storeAddressData: any;
	editStoreDetailsSelection: any;
	showStoreAddress: boolean;
	existOrderData: any;
	setStoreAddressData: any;
}

export const StoreAddress = (props: IStoreAddress) => {
	const isWMSPanel = appType === 'AppWms';
	const {
		isNewStoreAddress,
		setIsNewStoreAddress,
		addNewStoreAddress,
		editStoreDetailsSelection,
		storeAddressData,
		editStoreAddress,
		showStoreAddress,
		existOrderData,
		setStoreAddressData
	} = props;
	const {
		authState: { selectedCompanyIds }
	} = useContext(AuthContext);
	const [searchTerm, setSearchTerm] = useState('');

	const handleSelect = (value: any) => {
		setSearchTerm('');
		editStoreDetailsSelection(value);
	};

	const handleFilterOption = (input: string, option: any) => {
		return option?.children.props.text?.toLowerCase().includes(input.toLowerCase());
	};

	const handleSearchByQuery = async (input: string) => {
		setSearchTerm(input);

		// Check if the input length is greate than 2 and all characters are number
		const containsNumber = input.length > 2 && /^\d+$/.test(input);

		if (containsNumber) {
			const {
				data: { storeAddressData }
			} = await getStoreAddress(isWMSPanel ? selectedCompanyIds?.[0] : '', input, true, 'phone');
			setStoreAddressData(storeAddressData);
			return;
		}
		const {
			data: { storeAddressData }
		} = await getStoreAddress(isWMSPanel ? selectedCompanyIds?.[0] : '', input, true);
		setStoreAddressData(storeAddressData);
	};
	useEffect(() => {
		handleSearchByQuery(searchTerm);
	}, [searchTerm]);

	return (
		<>
			{!isNewStoreAddress && showStoreAddress && (
				<div>
					<h4 className={styles.heading}>{IManualOrderFormName.STORE_ADDRESS_DETAILS}</h4>

					<div style={{ display: 'flex' }}>
						<Form.Item
							name={IManualOrderFormName.STORE_ADDRESS}
							label="Store Address"
							rules={[{ required: true, message: 'Please select store address' }]}
							initialValue={existOrderData?.shippingD2RAddressId}
						>
							<Select
								style={{ width: '650px' }}
								placeholder="Select Store Address"
								showSearch
								optionFilterProp="children"
								onSelect={handleSelect}
								onSearch={setSearchTerm}
								filterOption={handleFilterOption}
							>
								{storeAddressData.map(({ id, firstName, lastName, pincode, addressLine1, isActive, phone }: any) => {
									const fullAddress = `${firstName} ${lastName}, ${phone},${pincode}, ${addressLine1}, `;
									return (
										isActive && (
											<Select.Option value={id} key={id}>
												<HighlightedText text={fullAddress} highlight={searchTerm} />
											</Select.Option>
										)
									);
								})}
							</Select>
						</Form.Item>
						<Button
							style={{ marginLeft: '2rem', marginTop: '1.8rem' }}
							type="primary"
							onClick={() => setIsNewStoreAddress(true)}
						>
							New Store Address
						</Button>
					</div>
				</div>
			)}
			{isNewStoreAddress && (
				<ManualOrderAddressForm
					formName={IManualOrderFormName.STORE_ADDRESS}
					customStyle={''}
					editAddress={editStoreAddress}
					formHeading={IManualOrderFormName.STORE_ADDRESS_DETAILS}
					onCreateNewStoreAddress={() => addNewStoreAddress()}
					onCancelNewStoreAddress={() => setIsNewStoreAddress(false)}
					isStoreAddressForm={true}
					existOrderData={null}
				/>
			)}
		</>
	);
};
